import React from 'react';
import { useChatContext } from '../../state/ChatContextProvider';
import { useTranslation } from '../../state/LanguageProvider';
import ChatContextDropdown from './ChatContextDropdown';
import { IDropdownOption } from '@fluentui/react';

const ProvinceSelectionDropdown: React.FC = () => {
    const { getTranslation } = useTranslation();
    const { availableChatContexts, provinceKey, setProvinceKey } = useChatContext();

    const provinceDropdownOptions = availableChatContexts
        .flatMap((context) =>
            Object.entries(context)
                .filter(([key]) => key.toLowerCase() === 'provinces')
                .flatMap(([_, provinces]) =>
                    Object.entries(provinces).map(([key, text]) => ({
                        key,
                        text,
                    }))
                )
        );

    const handleProvinceChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setProvinceKey(option.key as string);
        }
    };
    

    return (
        <ChatContextDropdown
            placeholder={getTranslation('select_province')}
            selectedKey={provinceKey}
            options={provinceDropdownOptions}
            onChange={handleProvinceChange}
        />
    );
};

export default ProvinceSelectionDropdown;
