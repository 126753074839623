import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import TelusLogoEN from "../../assets/TELUS_Logo_English.svg";
import TelusLogoFR from "../../assets/TELUS_Logo_French.svg";

import MobileChatSettings from '../../components/ChatSettings/MobileChatSettings/MobileChatSettings';
import DesktopChatSettings from '../../components/ChatSettings/DesktopChatSettings/DesktopChatSettings';
import { Dialog, Stack, Dropdown,IDropdownStyles, IDropdownOption } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { UserGuideButton } from "../../components/common/Button";
import UserGuide from "../../components/UserGuide/UserGuide";
import { AppStateContext } from "../../state/AppProvider";
import {useTranslation} from "../../state/LanguageProvider";
import { FrontendSettings } from "../../api";
import LayoutSkeleton from "./LayoutSkeleton";
import { useChatContext } from '../../state/ChatContextProvider';

const Layout = () => {
    const appStateContext = useContext(AppStateContext);
    const frontEndSettings: FrontendSettings = appStateContext?.state.frontendSettings!;
    const isMultiLang = frontEndSettings?.SUPPORT_MULTI_LANG || false;
    const isMutiContext = frontEndSettings?.SUPPORT_MULTI_CONTEXT || false; 
    const isChatSettingsEnabled = frontEndSettings?.CHAT_SETTINGS_ENABLED || false;
    
    const [isUserGuidePanelOpen, setIsUserGuidePanelOpen] = useState<boolean>(false);
    const { getTranslation, isLoading } = useTranslation();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 700);
    const {chatRegionKey, regionExists} = useChatContext();

    const TelusLogos = {
        "TelusLogoEN": TelusLogoEN,
        "TelusLogoFR": TelusLogoFR,
    };
    const TelusLogo = TelusLogos[getTranslation("telus_logo") as keyof typeof TelusLogos];

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {window.removeEventListener('resize', handleResize);};
      }, []);

    const handleUserGuideClick = () => {
        setIsUserGuidePanelOpen(true);
    };

    const handleUserGuidePanelDismiss = () => {
        setIsUserGuidePanelOpen(false);
    };

    function handleChatBotTitle(): string
    {
        const appName = getTranslation('app_name')
        const existingAppName = appName.slice(0, appName.lastIndexOf(" "));
        if (regionExists)
        {
            let region: string[];
            region = chatRegionKey.split("_"); //AZURE_SEARCH_REGION_INDEX_Australia
            const selRegionName = region[region.length - 1];
            const regionAppName = existingAppName.concat(" ", selRegionName);
            return regionAppName
        }
        else
            return appName
    };

    if (isLoading) {
        return <LayoutSkeleton />;
    }

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack className={styles.headerContainer}>
                    {isChatSettingsEnabled && isMobile && (
                        <MobileChatSettings isMultiLang={isMultiLang} isMultiContext={isMutiContext}/>
                    )}
                    <div className={styles.headerContainer1}>
                        <Stack horizontal verticalAlign="center">
                            <img
                                src={TelusLogo}
                                className={styles.headerIcon}
                                aria-hidden="true"
                            />
                            <div className={styles.headerTitleContainer}>
                                <h1 className={styles.headerTitle}>{ handleChatBotTitle() }</h1>
                            </div>
                        </Stack>
                    </div>
                    <Stack className={styles.headerContainer2} horizontal tokens={{ childrenGap: 20 }}>

                        <div id="header-portal-container"></div>

                        {                    
                            <Stack className={styles.headerIconContainer} onClick={handleUserGuideClick} horizontal tokens={{ childrenGap: 11 }}>
                                <UserGuideButton className={styles.userGuideButtonFlexContainer} />
                                <label className={styles.iconLabel}> {getTranslation("user_guide")} </label>
                            </Stack>
                        }

                    </Stack>
                </Stack>
            </header>
            
            {isChatSettingsEnabled && !isMobile ? (
                <div className={styles.flexContainerRow}> 
                    <DesktopChatSettings isMultiLang={isMultiLang} isMultiContext={isMutiContext}/>
                    <Outlet/>
                </div>
            ) : (
                <Outlet/>
            )}
            
            <Dialog 
                onDismiss={handleUserGuidePanelDismiss}
                hidden={!isUserGuidePanelOpen}
                styles={{
                    
                    main: [{
                        width: 'auto',
                        },
                        {
                        selectors: {
                          ['@media (min-width: 480px)']: {
                            maxWidth: '1000px',
                            background: "#FFFFFF",
                            boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "8px",
                            maxHeight: '500px',
                            minHeight: '100px',
                            fontFamily: "HelveticaNowTELUS",
                          }
                        }
                      }]
                }}
                dialogContentProps={{
                    title: (
                        <span style={{ fontFamily: "HelveticaNowTELUS" }}>
                          {getTranslation("general_user_guide")}
                        </span>
                      ),
                    showCloseButton: true,
                }}
            >
                <Stack horizontal verticalAlign="center" style={{gap: "8px"}}>
                    <div>
                        <UserGuide/>
                    </div>
                </Stack>
            </Dialog>
        </div>
    );
};

export default Layout;