import React, { useState, useContext } from 'react';
import { Panel, PanelType, ChoiceGroup, IChoiceGroupOption, IconButton, Overlay, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation } from "../../../state/LanguageProvider";
import styles from './MobileChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';
import ChatContextSelectionDropdown from "../../ChatContext/ChatContextDropdown";
import ChatSettingIcon from '../../../assets/Chat_Settings_Purple.svg';
import CloseIcon from "../../../assets/Close_Icon.svg";

interface MobileChatSettings {
    isMultiContext: boolean;
    isMultiLang: boolean;
}

const MobileChatSettings: React.FC<MobileChatSettings> = ({isMultiLang, isMultiContext}) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const { chatContextKey, setChatContextKey, contextExists, chatRegionKey, setChatRegionKey, regionExists, availableChatContexts, provinceExists } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();
    
    const chatContextOptions:any = []
    const chatRegionOptions:any = []
    const identifyContext: any = availableChatContexts.map(function(items) {
        Object.entries(items).map(([key, text]) => {
            if (key.toLowerCase() === "contexts") {
                chatContextOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            } else if (key.toLowerCase() === "region") {
                chatRegionOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            }
        });
    });

    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const openPanel = () => setIsPanelOpen(true);
    const closePanel = () => setIsPanelOpen(false);

    return (
        <div className={styles.chatSettingsButtonContainer}>
            <img src={ChatSettingIcon} title="Settings" aria-label={getTranslation("settings")} onClick={openPanel} className={commonStyles.chatSettingsButton}/>

            <Panel
                isOpen={isPanelOpen}
                onDismiss={closePanel}
                type={PanelType.customNear}
                customWidth="80%"
                isLightDismiss
                className={styles.chatSettingsPanel}
                onRenderNavigationContent={() => (
                    <img src={CloseIcon} className={styles.closeIcon} aria-label={getTranslation("close_panel")} onClick={closePanel} />
                )}
            >
                <div className={commonStyles.panelHeader}>
                    <img src={ChatSettingIcon} title="Settings" aria-label={getTranslation("settings")} onClick={openPanel} className={commonStyles.chatSettingsButton}/>
                    <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                </div>

                {isMultiLang && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("language")}
                        selectedKey={language}
                        onChange={(_, option) => setLanguage(option?.key as Language)}
                        options={languageOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

                {(isMultiContext && regionExists) && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("help_me_with")}
                        selectedKey={chatContextKey}
                        onChange={(_, option) => setChatRegionKey(option?.key as string)}
                        options={chatRegionOptions[0]}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

                {(isMultiContext && contextExists) && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("help_me_with")}
                        selectedKey={chatContextKey}
                        onChange={(_, option) => setChatContextKey(option?.key as string)}
                        options={chatContextOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

            </Panel>

            {isPanelOpen && <Overlay />}
        </div>
    );
};

export default MobileChatSettings;
