import React, { useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, IconButton, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation} from "../../../state/LanguageProvider";
import styles from './DesktopChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';

import ChevronRight from '../../../assets/Chevron_Right_Grey.svg';
import ChevronLeft from '../../../assets/Chevron_Left_Grey.svg';
import ChatSettingIcon from '../../../assets/Chat_Settings_Purple.svg';

interface DesktopChatSettingsProps {
    isMultiContext: boolean;
    isMultiLang: boolean;
}

const DesktopChatSettings: React.FC<DesktopChatSettingsProps> = ({isMultiLang, isMultiContext}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const { chatContextKey, setChatContextKey, contextExists, chatRegionKey, setChatRegionKey, regionExists, availableChatContexts } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();


    const chatContextOptions:any = []
    const chatRegionOptions:any = []
    const identifyContext: any = availableChatContexts.map(function(items) {
        Object.entries(items).map(([key, text]) => {
            if (key.toLowerCase() === "contexts") {
                chatContextOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            } else if (key.toLowerCase() === "region") {
                chatRegionOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            }
        });
    })
    
    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className={`${styles.desktopChatSettingsContainer} ${isExpanded ? styles.desktopExpanded : styles.desktopCollapsed}`}>
            <div className={styles.desktopToggleButtonContainer} onClick={toggleExpand}>
                <img src={isExpanded ? ChevronLeft : ChevronRight} title="Toggle Settings" aria-label={getTranslation("toggle_settings")} className={styles.desktopToggleButton} />
            </div>

            {isExpanded && (
                <div className={styles.desktopSettingsContent}>
                    <div className={commonStyles.panelHeader}>
                        <img src={ChatSettingIcon} aria-label={getTranslation("settings")} className={commonStyles.chatSettingsButton}/>
                        <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                    </div>

                    {isMultiLang && (
                    <div className={commonStyles.choiceGroupContainer}>
                        <ChoiceGroup
                            label={getTranslation("language")}
                            selectedKey={language}
                            onChange={(_, option) => setLanguage(option?.key as Language)}
                            options={languageOptions}
                            styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                        />
                    </div>)}


                    {(isMultiContext && regionExists) && (

                        <div className={commonStyles.choiceGroupContainer}>
                            <ChoiceGroup
                                label="Region"
                                selectedKey={chatRegionKey}
                                onChange={(_, option) => {setChatRegionKey(option?.key as string)}}
                                options={chatRegionOptions[0]}
                                styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                            />
                        </div>
                    )}

                    {(isMultiContext && contextExists) && (

                        <div className={commonStyles.choiceGroupContainer}>
                            <ChoiceGroup
                                label={getTranslation("help_me_with")}
                                selectedKey={chatContextKey}
                                onChange={(_, option) => setChatContextKey(option?.key as string)}
                                options={chatContextOptions[0]}
                                styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                            />
                        </div>
                    )}

                </div>
            )} 
        </div>
    );
};

export default DesktopChatSettings;
