import styles from "./UserGuide.module.css";
import ClearHistory from "../../assets/Clear_Chat_Icon.svg";
import StopGeneratingEN from "../../assets/Stop_Generating.jpg";
import StopGeneratingFR from "../../assets/Stop_Generating_FR.jpg";
import ThumbsUp from "../../assets/Thumbs_Up_Icon.svg";
import ThumbsDown from "../../assets/Thumbs_Down_Icon.svg";
import ThumbsUpActive from "../../assets/Thumbs_Up_Icon_Active.svg";
import ThumbsDownActive from "../../assets/Thumbs_Down_Icon_Active.svg";
import Copy from "../../assets/Copy_Icon.svg";
import Regenerate from "../../assets/Regenerate_Icon.svg";
import DownloadIcon from "../../assets/Download_Icon_Purple.svg";
import CloseIcon from "../../assets/Close_Icon.svg";
import SendIcon from "../../assets/Send_Icon.svg";
import SendIconActive from "../../assets/Send_Icon_Active.svg";

import { useTranslation} from "../../state/LanguageProvider";

const UserGuide = () => {
    const { getTranslation } = useTranslation();

    const StopGeneratingImages = {
        "StopGeneratingEN": StopGeneratingEN,
        "StopGeneratingFR": StopGeneratingFR,
    };
    const StopGeneratingImage = StopGeneratingImages[getTranslation("stop_generating_image") as keyof typeof StopGeneratingImages];

    return (
        <div>  
            <p>{getTranslation('ug_intro_paragraph_1')}</p>  
            <p>{getTranslation('ug_intro_paragraph_2')}</p>  
            <p>{getTranslation('ug_intro_paragraph_3')}</p>  
            <p><strong>{getTranslation('ug_examples_heading')}</strong></p>  
            <p>{getTranslation('ug_example_1')}</p>  
            <p>{getTranslation('ug_example_2')}</p>  
            <p>{getTranslation('ug_example_3')}</p>  
            <h2>{getTranslation('ug_features_heading')}</h2>
            <table className={styles.userGuideTable}>  
                <tbody>  
                <tr className={styles.headerRow}>  
                    <th className={styles.headerRowTextContainer}>  
                        <p className={styles.headerRowTextCentered}>  
                        <strong>{getTranslation('ug_table_header_active_state')}</strong>  
                        </p>  
                    </th>  
                    <th className={styles.headerRowTextContainer}>  
                        <p className={styles.headerRowTextCentered}>  
                        <strong>{getTranslation('ug_table_header_inactive_state')}</strong>  
                        </p>  
                    </th>  
                    <th className={styles.headerRowTextContainer}>  
                        <p>  
                        <strong>{getTranslation('ug_table_header_purpose')}</strong>  
                        </p>  
                    </th>  
                </tr>  
                <tr>  
                    <td>
                        <img src={ClearHistory} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td> 
                    <td>{getTranslation('ug_clear_chat_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={SendIconActive} className={styles.ugImg} aria-hidden="true"/>
                    </td>
                    <td className={styles.noHover}>
                        <img src={SendIcon} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td>{getTranslation('ug_send_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover} id={styles.stopGenerating}>
                        <img src={StopGeneratingImage} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_stop_generating_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                    <img src={Regenerate} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_regenerate_response_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={ThumbsUpActive} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>
                        <img src={ThumbsUp} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td>{getTranslation('ug_thumbs_up_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={ThumbsDownActive} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>
                        <img src={ThumbsDown} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td>{getTranslation('ug_thumbs_down_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={Copy} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>
                        <img src={Copy} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td>{getTranslation('ug_copy_to_clipboard_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={DownloadIcon} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_download_source_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <img src={CloseIcon} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_close_citation_panel_button')}</td>  
                </tr>  
                </tbody>  
            </table>  
        </div>  
        
        );
};

export default UserGuide;

